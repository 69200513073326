/* Home.module.css */
.navbar {
    background-color: var(--mantine-color-body);
    /*height: calc(840 * 1px); */
    height: calc(100vh - 100);
    width: calc(300 * 1px);
    padding: var(--mantine-spacing-md);
    display: flex;
    flex-direction: column;
    border-right: calc(1 * 1px) solid var(--mantine-color-gray-3); /* Simplified for example */
}

.navbarMain {
    flex: 1;
    /*margin-top: var(--mantine-spacing-xl);*/
}

.title {
    text-transform: uppercase;
    letter-spacing: calc(-0.25 * 1px);
}

.link {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: var(--mantine-font-size-xl);
    color: var(--mantine-color-gray-7);
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
    border-radius: var(--mantine-radius-sm);
    font-weight: 500;
}

.link:hover {
    background-color: var(--mantine-color-gray-0); /* Simplified for example */
    color: var(--mantine-color-black); /* Simplified for example */
}

.link[data-active] {
    background-color: var(--mantine-color-blue-light);
    color: var(--mantine-color-blue-light-color);
}

.link[data-active]:hover {
    background-color: var(--mantine-color-blue-light);
    color: var(--mantine-color-blue-light-color);
}

.linkIcon {
    color: var(--mantine-color-gray-6);
    margin-right: var(--mantine-spacing-sm);
    width: calc(30 * 1px);
    height: calc(30 * 1px);
    margin-right: var(--mantine-spacing-sm); /* Adjust spacing as needed */
}

.footer {
    border-top: calc(1 * 1px) solid var(--mantine-color-gray-4);
    padding-top: var(--mantine-spacing-md);
}

.linkContent {
    display: flex;
    align-items: center;
}

/* Ensure that your span or text has a matching line-height if needed */
span {
    line-height: 1; /* This should be adjusted based on your design requirements */
}
